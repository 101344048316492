import '../App.css';

import React from "react";
import { useEffect, useState } from 'react';




export const BasePopup = ({ closePopup, children }) => {
    const [modalPosition, setModalPoisiton] = useState(0)
    const [width, setWidth] = useState(0)
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const element = document.getElementById("qrBox");
        let rect = element.getBoundingClientRect();
        let correlaction = windowSize.width >= 640 ? 340 : 0
        setModalPoisiton([rect.top + window.scrollY, rect.right, rect.bottom, rect.left - correlaction])
    }, [windowSize.width, windowSize.height])
 
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
 
        window.addEventListener('resize', handleResize);
        const element = document.getElementById("qrBox");
        setWidth(element.offsetWidth)
 
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // max-w-[308px]
    return (
        <div className="popup-container ">
            <div className="popup-body z-40 text-left h-[558px] min-w-[308px] w-100 p-5" style={{width: width, top: modalPosition[0], left: modalPosition[3]}}>
                <span onClick={closePopup} className='closePopup'></span>
                {children}
            </div>
        </div>
    );
};