import React from "react";
import { useEffect, useState } from 'react';
import UploadAndDisplayImage from './ImageUploader'
import ImageUpload from './ImageUploadBase64'
import { Button } from './Button'
import axios from 'axios';
import { BasePopup } from './BasePopup';


export const PopupLogo = ({ closePopup, setLogo, file, setFile}) => {
    const [modalPosition, setModalPoisiton] = useState(0)
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [imageSrc, setImageSrc] = useState('');
    

    // Base64
    const handleSetLogo = (binaryData) => {
        // Convert binary data to Base64
        const base64String = btoa(
          new Uint8Array(binaryData).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        setLogo(base64String);
     };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };


    return (
        <BasePopup closePopup={closePopup}>
            <div className='flex flex-col justify-between w-full h-full'>
                <div>
                    <div className=''>
                        <span className='text-[16px] font-bold'>Upload a logo:</span>
                    </div>
                    {/* Image as attachment */}
                    <UploadAndDisplayImage file={file} setFile={setFile} handleFileChange={handleFileChange} />
                    
                    {/* base64 image */}
                    {/* <ImageUpload  setImageBinary={handleSetLogo} /> */}

                </div>
                <div className='flex pb-4'>
                    <Button className="button-alert mx-2" onClick={() => {closePopup(); }} text="Cancel"/>
                    <Button className="button mx-2" onClick={closePopup} text="Apply"/>
                </div>
            </div>
        </BasePopup>
    );
};