import '../App.css';
import React from "react";
import { useEffect, useState } from 'react';
import { Button } from './Button'
import { BasePopup } from './BasePopup';
import { ColorPicker } from "react-color-palette";
import Popup from 'reactjs-popup';
import "react-color-palette/css";
import 'reactjs-popup/dist/index.css';

import Select from 'react-select';

const dotOptions = [
    { value: 'square', label: 'square' },
    { value: 'rounded', label: 'rounded' },
    { value: 'circle', label: 'circle' },
    { value: 'horizontal', label: 'horizontal' },
    { value: 'vertical', label: 'vertical' },
];

const outerEyeOptions = [
    { value: 'square', label: 'square' },
    { value: 'circle', label: 'circle' },
    { value: 'rounded', label: 'rounded' },
    { value: 'rounded_with_top_right', label: 'rounded tl' },
    { value: 'rounded_with_top_left', label: 'rounded tr' },
    { value: 'rounded_with_bottom_right', label: 'rounded br' },
    { value: 'rounded_with_bottom_left', label: 'rounded bl' },

];

const innerEyeOptions = [
    { value: 'square', label: 'square' },
    { value: 'circle', label: 'circle' },
    { value: 'rounded', label: 'rounded' },
];



const Input = ({position, label, setUsePresetStyle, outerEyeStyles, setOuterEyeStyles, innerEyeStyles, setInnerEyeStyles}) => {

    useEffect(() => {
        setUsePresetStyle(false)
    }, [setUsePresetStyle, outerEyeStyles, innerEyeStyles])

    const updateState = (styles, setStyles, v, p) => {
        let newf = [...styles]
        newf[p] = v
        setStyles(newf)
    }

    useEffect(() => {
        console.log(1)
        console.log(outerEyeStyles)
        console.log(innerEyeStyles)
    }, [outerEyeStyles, setOuterEyeStyles])

  
    return (
        <div className='mb-2'>
            <label className='text-[14px] font-bold'>{label}</label>
            <div className='flex gap-2'>
            <Select
                className="text-left text-[14px] flex-1"
                defaultValue={{'value': outerEyeStyles[position], 'label': outerEyeStyles[position]}}
                onChange={(value) => {updateState(outerEyeStyles, setOuterEyeStyles, value.value, position)}}
                options={outerEyeOptions}
            />
            <Select
                className="text-left text-[14px] flex-1"
                defaultValue={{'value': innerEyeStyles[position], 'label': innerEyeStyles[position]}}
                onChange={(value) => {updateState(innerEyeStyles, setInnerEyeStyles, value.value, position)}}
                options={innerEyeOptions}
            />
            </div>
        </div>
    );
};


const DotStyle = ({position, label, setUsePresetStyle, dotStyle, setDotStyle}) => {

    useEffect(() => {
        setUsePresetStyle(false)
    }, [dotStyle, setDotStyle])

    const updateState = (value) => {
        setDotStyle(value)
    }


  
    return (
        <div className='mb-2'>
            <label className='text-[14px] font-bold'>{label}</label>
            <div className='flex gap-2'>
            <Select
                className="text-left text-[14px] flex-1"
                defaultValue={{'value': dotStyle, 'label': dotStyle}}
                onChange={(value) => {updateState(value.value)}}
                options={dotOptions}
            />
            </div>
        </div>
    );
};





export const PopupStyles = ({ dotStyle, setDotStyle, outerEyeStyles, setOuterEyeStyles, topLeftOuterEyeStyle, innerEyeStyles, setInnerEyeStyles, setTopLeftOuterEyeStyle, topLeftInnerEyeStyle, setTopLeftInnerEyeStyle, topRightOuterEyeStyle, setTopRightOuterEyeStyle, topRightInnerEyeStyle, setTopRightInnerEyeStyle, bottomLeftOuterEyeStyle, setBottomLeftOuterEyeStyle, bottomLeftInnerEyeStyle, setUsePresetStyle, setBottomLeftInnerEyeStyle, closePopup }) => {
    const [open, setOpen] = useState(false)
    const [openOuterEye, setOpenOuterEye] = useState(false)
    const [openInnerEye, setOpenInnerEye] = useState(false)
    const [openBackgroundColor, setOpenBackgroundColor] = useState(false)


    return (
        <BasePopup closePopup={closePopup}>
            <div className='flex flex-col justify-between w-full h-full'>
                    <div>
                        <div className='mb-2'>
                            <span className='text-[18px] font-bold'>Select styles:</span>
                        </div>

                        <Input label={"Top left eye"} position={0} outerEyeStyles={outerEyeStyles} innerEyeStyles={innerEyeStyles} setInnerEyeStyles={setInnerEyeStyles} setOuterEyeStyles={setOuterEyeStyles} setUsePresetStyle={setUsePresetStyle} />
                        <Input label={"Top right eye"} position={1} outerEyeStyles={outerEyeStyles} innerEyeStyles={innerEyeStyles} setInnerEyeStyles={setInnerEyeStyles} setOuterEyeStyles={setOuterEyeStyles} setUsePresetStyle={setUsePresetStyle} />
                        <Input label={"Bottom left eye"} position={2} outerEyeStyles={outerEyeStyles} innerEyeStyles={innerEyeStyles} setInnerEyeStyles={setInnerEyeStyles} setOuterEyeStyles={setOuterEyeStyles} setUsePresetStyle={setUsePresetStyle} />
                        <DotStyle label={"Dot style"} dotStyle={dotStyle} setDotStyle={setDotStyle} setUsePresetStyle={setUsePresetStyle} />
                        {/* <Input label={"Top left eye"} outerEyeStyles={outerEyeStyles} innerEyeStyles={innerEyeStyles} setInnerEyeStyles={setInnerEyeStyles} position={0} setOuterEyeStyles={setOuterEyeStyles} setUsePresetStyle={setUsePresetStyle} value={topLeftOuterEyeStyle} setValue={setTopLeftOuterEyeStyle} value2={ topLeftInnerEyeStyle} setValue2={setTopLeftInnerEyeStyle} /> */}
                        {/* <Input label={"Top right eye"} setUsePresetStyle={setUsePresetStyle} value={topRightOuterEyeStyle} setValue={setTopRightOuterEyeStyle} value2={topRightInnerEyeStyle} setValue2={setTopRightInnerEyeStyle} />
                        <Input label={"Bottom left eye"} setUsePresetStyle={setUsePresetStyle} value={bottomLeftOuterEyeStyle} setValue={setBottomLeftOuterEyeStyle} value2={bottomLeftInnerEyeStyle} setValue2={setBottomLeftInnerEyeStyle} />
                         */}
                        {/* <Input label={"Top right eye"}/> */}
                        {/* <Input label={"Top left eye"}/>
                        <Input label={"Top left eye"}/>

                        <Input label={"Top left eye"}/> */}



                        {/* <label className='text-[16px] font-bold'>Background:</label>
                        {fillBackground &&
                            <ColorInput open={openBackgroundColor} setOpen={setOpenBackgroundColor} color={backgroundColor} setColor={setBackgroundColor} callback={callback} />
                        }
                        <div className='flex align-center mt-2 mb-2'>
                            <input
                                className="text-violet-500 mr-2 border-gray-300 peer rounded-lg w-6 h-6"
                                id="background"
                                type="checkbox"
                                checked={fillBackground}
                                onChange={() => setFillBackground(!fillBackground)}
                                value="background"
                            />
                            <label
                                className="cursor-pointer"
                                htmlFor="background"
                            >
                                <p className="inline">Fill background</p>
                            </label>
                        </div> */}



                    </div>
                    
                    <div className='flex pb-4'>
                        <Button className="button-alert mx-2" onClick={()=>{closePopup()}} text="Close"/>
                        <Button className="button mx-2" onClick={()=>{closePopup()}} text="Apply"/>
                    </div>
                </div>
        </BasePopup>
    );
};