import {
    EmailShareButton,
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton,
    TwitterIcon,
    FacebookIcon,
    PinterestIcon,
} from "react-share";

import share_img from '../static/share.jpg';


export function ShareSocial() {
    return (
        <>
            <div className='font-dmsans flex justify-center bg-bermudagrey px-6 sm:px-0'>
                <div className='max-w-[1164px] container pt-20 pb-20'>
                    <h3 className="text-[28px] font-semibold mb-6">In case you like this tool</h3>
                    <p className="text-[18px] mb-8">Please share our website so that more people will know about us.</p>

                    <FacebookShareButton url="https://light-qrcode.com" hashtag='qrcodegenerator' >
                        <FacebookIcon className="mx-2" size={60} round={true} />
                    </FacebookShareButton>

                    <TwitterShareButton url="https://light-qrcode.com" title="Looking for a free and easy-to-use QR code generator? Try light QR code generator" hashtags={["qrcodegenerator"]} >
                        <TwitterIcon className="mx-2" size={60} round={true} />
                    </TwitterShareButton>

                    <PinterestShareButton url="https://light-qrcode.com" media="https://flink.ink/share.jpg">
                        <PinterestIcon className="mx-2" size={60} round={true} />
                    </PinterestShareButton>
                </div>
            </div>
        </>
    )
}
