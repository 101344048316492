import '../App.css';
import React, { useEffect, useState } from 'react';
import { colors } from '../constants'
import color_circle from '../static/color_circle.png';


export const getBackgroundColorForPresetColorStyle = (color) => {
    return isNeedBackBackground(color) ? "#000000" : "#FFFFFF"
}

export const isNeedBackBackground = (color) => {
    return color === "#FFFFFF"
}

function ColorDot({color, colorHex, colorHandler, currentColor}) {
    const colorbg = color ? "bg-" + color : "bg-black"
    const border = isNeedBackBackground(colorHex) ? " border border-black" : ""

    return (
      <button 
        value={colorHex} 
        className={'color-dot mr-[7px] ' + colorbg + border} 
        onClick={(e)=> {colorHandler(e);}} 
        style={colorHex === currentColor? {"outline":"4px solid #A1C1FF"} : {}}>
      </button>
    )
  }

export function ColorOptions({ dotColor, changeColor, setOpenColorsSettings}){
    return (
        <div>
            <span className='text-[16px] font-bold'>Color</span>
            <div className='flex justify-between pt-1'>
            <div className=''>
                { colors.map(function(color) {
                return (
                    <ColorDot key={color.color} color={color.name} colorHex={color.color} currentColor={dotColor.hex} colorHandler={changeColor} />
                )
                })}
            </div>
            <button className='mb-2 settings-header' onClick={() => setOpenColorsSettings(true)}><img className='image w-[24px] inline mr-2 mb-[2px]' src={color_circle} alt="logo" />More</button>
            </div>
        </div>
    )
}
