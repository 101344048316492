import shape from './static/shape.svg';
import shape2 from './static/shape2.svg';
import shape3 from './static/shape3.svg';
import shape4 from './static/shape4.svg';


// let BASE_URL3 = "12"

// if (process.env.BASE_URL !== 'production') {
//   BASE_URL3 = "123"
// }

export const colors = [
    {
      "name": "black",
      "color": "#000000"
    },
    {
      "name": "white",
      "color": "#FFFFFF"
    },
    {
      "name": "green",
      "color": "#479062"
    },
    {
      "name": "blue",
      "color": "#2366E8"
    }
  ]
  
export const styles = [
  {
    "name": "rounded",
    "icon": shape,
  },
  {
    "name": "square",
    "icon": shape2,
  },
  {
    "name": "circle",
    "icon": shape3,
  },
  {
    "name": "horizontal",
    "icon": shape4,
  },
]
