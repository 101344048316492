import qr from '../static/qr-boilerplate.png';

import qr_2 from '../static/qr-2.png';



import img_download from '../static/qr-download.png';
import img_frames from '../static/qr-frames.png';
import img_brand from '../static/qr-brand.png';
import img_open from '../static/qr-open.png';

export function FeaturesSection() {
    return (
        <>
        <div className='font-dmsans flex justify-center bg-bermuda pt-20 px-6 sm:px-0'>
            <div className='max-w-[1164px] container pb-20 pt-6 md:px-4'>
                <div className='w-full md:w-[600px] mx-auto md:px-4'>
                    <h2 className="font-dmsans text-4xl leading-normal font-semibold mb-6">Tired of QR codes with built-in ads and expiration dates? </h2>
                    <p className="text-[18px] mb-8">First QR code generator that combines together:</p>
                </div>

                <div className='flex justify-center flex-wrap'>
                    <div className="bg-white border border-[#D7E0F3] w-full md:w-[500px] md:mx-4 my-6 pt-10 pb-20 px-8 md:px-20 rounded-[30px] box-shadow">
                        <img className='max-w-[160px] mx-auto' src={qr} />
                        <h4 className="text-[26px]">No expiration date and built-in ads.</h4>
                    </div>

                    <div className="bg-white border border-[#D7E0F3] w-full md:w-[500px] md:mx-4 my-6 pt-10 pb-20 px-8 md:px-20 rounded-[30px] box-shadow">
                        <img className='max-w-[160px] mx-auto' src={img_frames} />
                        <h4 className="text-[26px]">Apply frames<br/> in seconds.</h4>
                    </div>
                    <div className="bg-white border border-[#D7E0F3] w-full md:w-[500px] md:mx-4 my-6 pt-10 pb-20 px-8 md:px-20 rounded-[30px] box-shadow">
                        <img className='max-w-[160px] mx-auto' src={img_download} />
                        <h4 className="text-[26px]">Download qr codes in all popular image formats</h4>
                    </div>
                    <div className="bg-white border border-[#D7E0F3] w-full md:w-[500px] md:mx-4 my-6 pt-10 pb-20 px-8 md:px-20 rounded-[30px] box-shadow">
                        <img className='max-w-[160px] mx-auto' src={qr_2} />
                        <h4 className="text-[26px]">Fully customizable qr code design</h4>
                    </div>
                    <div className="bg-white border border-[#D7E0F3] w-full md:w-[500px] md:mx-4 my-6 pt-10 pb-20 px-8 md:px-20 rounded-[30px] box-shadow">
                        <img className='max-w-[160px] mx-auto' src={img_brand} />
                        <h4 className="text-[26px]">Brand QR codes with your own logo</h4>
                    </div>
                    <div className="bg-white border border-[#D7E0F3] w-full md:w-[500px] md:mx-4 my-6 pt-10 pb-20 px-8 md:px-20 rounded-[30px] box-shadow">
                        <img className='max-w-[160px] mx-auto' src={img_open} />
                        <h4 className="text-[26px]">No sign up required</h4>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}
