import React, { useState } from 'react';


export function MainContentSection() {

    return (
        <section className='font-dmsans flex justify-center bg-bermuda pt-10 px-6 sm:px-0'>
            <div className='max-w-[1164px] container pb-20 pt-6 md:px-4'>
                <div className='w-full md:w-[800px] mx-auto md:px-4'>
                <div className="mb-16">
                    <h1
                        className="text-5xl text-center font-bold mb-8 text-gray-900 leading-[3.25rem]"
                    >
                        Free QR Code Generator
                    </h1>
                    <p className='text-[18px] mb-8'>
                    Create custom QR codes for your website in seconds with our free, easy-to-use generator. No sign-up required, no technical skills needed. Simply enter your URL, customize your design if desired, and download your QR code instantly. Perfect for business cards, flyers, or social media posts, our tool helps you connect your offline audience to your online content effortlessly. Boost your digital presence and track engagement with our user-friendly QR codes. Start generating now and watch as your website becomes just a quick scan away for anyone with a smartphone.
                    </p>
                </div>

            </div>
            </div>
        </section>
    );
};