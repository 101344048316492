import React, { useState, useEffect } from 'react';
import axios from 'axios';


const url = process.env.REACT_APP_BASE_URL + "/form?"

function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

async function getImage(index, formDataValues, createdAt){

    const formData = new FormData();

    formData.append('file', formDataValues['file']);
    formData.append('color', formDataValues['color']);
    formData.append('color_outer_eye', formDataValues['color_outer_eye']);
    formData.append('color_inner_eye', formDataValues['color_inner_eye']);
    formData.append('data', formDataValues['data']);

    if (formDataValues['background_color']) {
      formData.append('background_color', formDataValues['background_color']);
    }

    if (formDataValues['frame']) {
      formData.append('frame', formDataValues['frame']);
    }

    if (formDataValues['preset_style']) {
      formData.append('preset_style', formDataValues['preset_style']);
    } else {
      formData.append('eye_styles', formDataValues['eye_styles']);
      formData.append('eye_inner_styles', formDataValues['eye_inner_styles']);
      formData.append('dot_style', formDataValues['dot_style']);
    }

    const res = await axios.post(
        url, formData,
        { 
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'arraybuffer' 
        }
      )

    const data = await res.data

    const base64Image = btoa(
        new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );


    return (
        <div key={index} className='bg-white border border-[#D7E0F3] w-[260px] w-full md:max-w-[260px] mr-0 md:mr-6 mb-4 md:mb-8 rounded-xl text-left p-2'>
            <div className='flex  h-full  truncate'>
                <div className='flex bg-white border border-[#D7E0F3] rounded-xl p-1'>
                    <img className='my-auto mx-auto min-w-[64px] w-[64px]' src={`data:image/svg+xml;base64,${base64Image}`}  alt="qr" />
                </div>
                <div className='ml-4'>
                    <p className='text-[14px]'>{timeSince( new Date(createdAt) )} ago</p>
                    <p className='text-[16px] font-semibold'>{formDataValues['data'] ? formDataValues['data']: "No data"}</p>
                    {/* <p className='font-semibold text-blue cursor-pointer'>more</p> */}
                </div>
            </div>
        </div>
    )
}


export function CreatedQRs({ created }) {
    const [qrComponents, setQrComponents] = useState([]);

    useEffect(() => {
        async function fetchQRs() {
            const components = await Promise.all(created.map(async (qr, index) => {
                return await getImage(index, qr.formDataValues, qr.created_at);
            }));
            setQrComponents(components);
        }

        fetchQRs();
    }, [created]);

    if (qrComponents.length > 0) {
        return (
            <div className='font-dmsans flex justify-center bg-bermuda px-6 sm:px-0'>
                <div className='max-w-[1164px] container pb-20 pt-6'>
                    <h3 className='text-[25px] text-left font-semibold'>Your QR codes:</h3>
                    <div className='flex flex-wrap py-4'>
                        {qrComponents}
                    </div>
                </div>
            </div>
        );
    } else {
        return null; // or some loading state
    }
}
