import '../App.css';
import React, { useEffect, useState } from 'react';
import { styles } from '../constants'



function StyleOption({icon, types, active, styleHandler}) {
    const isActive = active === types ? "style-option-active": ""
    return (
      <button
        value={types}
        className={'flex justify-center items-center style-option mr-2 ' + isActive}
        onClick={(e)=>styleHandler(e)}
        style={active === types ? {"outline":"4px solid #A1C1FF"} : {}}>
        <img src={icon} alt="logo" />
      </button>
    )
}

export function StyleOptions({active, styleHandler, setOpenStylesSettings}){
    return (
        <div className='mb-4'>
            <span className='text-[16px] font-bold'>Style</span>
            <div className='flex justify-between pt-1'>
            <div className='flex'>
                { styles.map(function(color, index) {
                    return (
                        <StyleOption icon={color.icon} key={color.name} types={color.name} active={active} styleHandler={styleHandler}  />
                    )
                })}
            </div>

            <button className='mb-2 settings-header' onClick={() => setOpenStylesSettings(true)}>More</button>
            </div>
        </div>
    )
}
