import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import logo_qr from './logo-lightqr.svg';
import example_qr from './static/example-created-qr.png';

import frame_logo from './static/frame.svg';
import logo_icon from './static/logo.svg';

import { PopupColors } from "./components/PopupColors";
import { PopupFrame } from "./components/PopupFrame";
import { PopupLogo } from "./components/PopupLogo";
import { PopupStyles } from "./components/PopupStyles";  
import { ButtonGroup } from './components/ButtonGroup/ButtonGroup'
import { StyleOptions } from './components/StyleOptions'
import { ColorOptions, isNeedBackBackground} from './components/ColorOptions'
import { ShareSocial } from './components/SocialShareSection'
import { CreatedQRs } from './components/CreatedQRs'
import { FeaturesSection } from './components/FeaturesSection'
import { FaqSection } from './components/FaqSection'
import { MainContentSection } from './components/MainContentSection'
import { Footer } from './components/Footer'
import { useColor, ColorService } from "react-color-palette";


const QRCodeImage = ({ data }) => <img src={`${data}`} />

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}


function BigInput(){
  const [imageSrc, setImageSrc] = useState('');
  const [isImageUpdating, setIsImageUpdating] = useState(false);
  const [created, setCreated] = useState([]);

  const [dotColor, setDotColor] = useColor("#000000");
  const [outerEyeColor, setOuterEyeColor] = useColor("#000000");
  const [innerEyeColor, setInnerEyeColor] = useColor("#000000");
  const [backgroundColor, setBackgroundColor] = useColor("#FFFFFF");

  const [usePresetColorStyle, setUsePresetColorStyle] = useState(true);
  const [usePresetStyle, setUsePresetStyle] = useState(true);

  const [fillBackground, setFillBackground] = useState(true);
  const [activePresetStyle, setActivePresetStyle] = useState('rounded');

  const [topLeftOuterEyeStyle, setTopLeftOuterEyeStyle] = useState({ value: 'rounded', label: 'rounded' });
  const [topLeftInnerEyeStyle, setTopLeftInnerEyeStyle] = useState({ value: 'rounded', label: 'rounded' });
  const [topRightOuterEyeStyle, setTopRightOuterEyeStyle] = useState({ value: 'rounded', label: 'rounded' });
  const [topRightInnerEyeStyle, setTopRightInnerEyeStyle] = useState({ value: 'rounded', label: 'rounded' });
  const [bottomLeftOuterEyeStyle, setBottomLeftOuterEyeStyle] = useState({ value: 'rounded', label: 'rounded' });
  const [bottomLeftInnerEyeStyle, setBottomLeftInnerEyeStyle] = useState({ value: 'rounded', label: 'rounded' });

  const [dotStyle, setDotStyle] = useState('rounded');
  const [outerEyeStyles, setOuterEyeStyles] = useState(['rounded', 'rounded', 'rounded']);
  const [innerEyeStyles, setInnerEyeStyles] = useState(['rounded', 'rounded', 'rounded']);
  
  const [activeFrame, setActiveFrame] = useState(undefined);
  const [tempActiveFrame, setTempActiveFrame] = useState(undefined);

  const [downloadImageFileFormat, setdownloadImageFileFormat] = useState('svg');

  const [logo, setLogo] = useState(null);
  const [file, setFile] = useState(null);

  const [data, setData] = useState('');

  const [openColorsSettings, setOpenColorsSettings] = useState(false);
  const [openFrameSettings, setOpenFrameSettings] = useState(false);
  const [openLogoSettings, setOpenLogoSettings] = useState(false);
  const [openStylesSettings, setOpenStylesSettings] = useState(false);

  const debouncedValue = useDebounce(data, 300);
  const debouncedDotColor = useDebounce(dotColor, 100);
  const debouncedOuterEyeColor = useDebounce(outerEyeColor, 100);
  const debouncedInnerEyeColor = useDebounce(innerEyeColor, 100);

  // FormaData request
  useEffect(() => {
    setIsImageUpdating(true)

    const formData = new FormData();
    formData.append('file', file);
    formData.append('color', dotColor.hex);
    formData.append('color_outer_eye', outerEyeColor.hex);
    formData.append('color_inner_eye', innerEyeColor.hex);
    formData.append('data', debouncedValue);

    if (tempActiveFrame || activeFrame) {
      formData.append('frame', tempActiveFrame || activeFrame);
    }

    if (usePresetStyle) {
      formData.append('preset_style', activePresetStyle);
    } else {
      formData.append('eye_styles', outerEyeStyles);
      formData.append('eye_inner_styles', innerEyeStyles);
      formData.append('dot_style', dotStyle);
    }
    
    const url = process.env.REACT_APP_BASE_URL + "/form?"

    axios.post(
      url, formData,
      { 
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'arraybuffer' 
      }
    )
      .then(response => {
        const base64Image = btoa(
          new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        setImageSrc(`data:image/svg+xml;base64,${base64Image}`);
        setIsImageUpdating(false)
      })
      .catch(error => {
        setIsImageUpdating(false)
        console.error('Error fetching the image:', error);
      });

  }, [debouncedValue, debouncedDotColor, debouncedOuterEyeColor, debouncedInnerEyeColor, dotStyle, innerEyeStyles, outerEyeStyles, activePresetStyle, tempActiveFrame, activeFrame, logo, file]);


  const handleDownload = (event) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('color', dotColor.hex);
    formData.append('color_outer_eye', outerEyeColor.hex);
    formData.append('color_inner_eye', innerEyeColor.hex);
    formData.append('data', debouncedValue);

    if (fillBackground && !activeFrame) {
      formData.append('background_color', backgroundColor.hex);
    }

    if (activeFrame) {
      formData.append('frame', activeFrame);
    }

    if (usePresetStyle) {
      formData.append('preset_style', activePresetStyle);
    } else {
      formData.append('eye_styles', outerEyeStyles);
      formData.append('eye_inner_styles', innerEyeStyles);
      formData.append('dot_style', dotStyle);
    }

    let format = downloadImageFileFormat
    formData.append('download_format', format);

    const url = process.env.REACT_APP_BASE_URL + "/download?"

    axios.post(
      url, formData,
      { 
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob' 
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'qr.' + format);
      document.body.appendChild(link);
      link.click();

      // Collect params to save it
      const formDataValues = {
        'file': file,
        'color': dotColor.hex,
        'color_outer_eye': outerEyeColor.hex,
        'color_inner_eye': innerEyeColor.hex,
        'data': debouncedValue,
      }
      if (fillBackground && !activeFrame) {
        formDataValues['background_color'] = backgroundColor.hex
      }
      if (activeFrame) {
        formDataValues['frame'] = activeFrame
      }
      if (usePresetStyle) {
        formDataValues['preset_style'] = activePresetStyle;
      } else {
        formDataValues['eye_styles'] = outerEyeStyles;
        formDataValues['eye_inner_styles'] = innerEyeStyles;
        formDataValues['dot_style'] = dotStyle;
      }
      addQRCodeToCollection(formDataValues);
      loadQRCodeToCollection();
    });
  }

  useEffect(() => {
    loadQRCodeToCollection();
  }, []);

  function addQRCodeToCollection (formData) {
    let qrs = JSON.parse(localStorage.getItem("createdQRs"));
    if (!qrs){
      qrs = [];
    }
    if (qrs.length > 7) {
      qrs.pop()
    }
    const message = {
        "formDataValues": formData,
        "created_at": new Date().toISOString()
    }
    qrs.unshift(message)
    localStorage.setItem("createdQRs", JSON.stringify(qrs));
  }
  function loadQRCodeToCollection () {
    let qrs = JSON.parse(localStorage.getItem("createdQRs"));
    if (qrs){
      setCreated(qrs)
    }
  }

  const handleChange = (event) => {
      setData(event.target.value);
  };

  useEffect(() => {
    if (!fillBackground) {
      setBackgroundColor(ColorService.convert("hex", "#FFFFFF"))
    }
  }, [fillBackground, setFillBackground])

  const changeColor = (e) => {
    setDotColor(ColorService.convert("hex", e.target.value))
    setOuterEyeColor(ColorService.convert("hex", e.target.value))
    setInnerEyeColor(ColorService.convert("hex", e.target.value))

    if (isNeedBackBackground(e.target.value)) {
      setBackgroundColor(ColorService.convert("hex", "#000000"))
    } else {
      setBackgroundColor(ColorService.convert("hex", "#FFFFFF"))
    }

    setUsePresetColorStyle(true);
  }
  const changeStyle = (e) => {

    setActivePresetStyle(e.currentTarget.value)
    if (e.currentTarget.value === "rounded"){
      setOuterEyeStyles(["rounded_with_bottom_right", "rounded_with_bottom_left", "rounded_with_top_left"])
      setInnerEyeStyles(["rounded", "rounded", "rounded"])
      setDotStyle("rounded")
    } else if (e.currentTarget.value === "square"){
      setOuterEyeStyles(["square", "square", "square"])
      setInnerEyeStyles(["square", "square", "square"])
      setDotStyle("square")
    } else if (e.currentTarget.value === "circle"){
      setOuterEyeStyles(["rounded_with_bottom_right", "rounded_with_bottom_left", "rounded_with_top_left"])
      setInnerEyeStyles(["rounded", "rounded", "rounded"])
      setDotStyle("circle")
    } else if (e.currentTarget.value === "horizontal"){
      setOuterEyeStyles(["rounded_with_bottom_right", "rounded_with_bottom_left", "rounded_with_top_left"])
      setInnerEyeStyles(["rounded", "rounded", "rounded"])
      setDotStyle("horizontal")
    }
    setUsePresetStyle(true)
  }
  const openPopup = (stateModal) => {
    // close all other popups
    stateModal(true)
  }
  const closePopup = (setOpenFrame) => {
    if (tempActiveFrame){
      setTempActiveFrame(undefined)
    }
    setOpenFrame(false)
  }

  return (
    <>
   <div className='font-dmsans flex justify-center bg-bermuda px-6 sm:px-0'>

      <div className='flex flex-wrap sm:flex-nowrap max-w-[1164px] container pb-20 pt-6' style={{transition: "2s"}}>

        <div className='box w-full bg-white h-[148px] sm:h-[558px] border border-[#D7E0F3] rounded-xl p-8 sm:mr-8 mb-4'>
          <textarea
            className='no-style-textarea input-text'
            id="123"
            name="postContent"
            value={data}
            onChange={handleChange}
            placeholder="Paste here any url or text..."
            style={{width: "100%", height: "100%"}}
          />
        </div>

        <div>
          {openColorsSettings ? <PopupColors 
            color={dotColor}
            setColor={setDotColor}
            outerEyeColor={outerEyeColor}
            setOuterEyeColor={setOuterEyeColor}
            innerEyeColor={innerEyeColor}
            setInnerEyeColor={setInnerEyeColor}
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
            fillBackground={fillBackground}
            setFillBackground={setFillBackground}
            setUsePresetColorStyle={setUsePresetColorStyle}
            closePopup={() => setOpenColorsSettings(false)} 
          /> : null}
        </div>

        <div>
          {openStylesSettings ? <PopupStyles
            topLeftOuterEyeStyle={topLeftOuterEyeStyle}
            setTopLeftOuterEyeStyle={setTopLeftOuterEyeStyle}
            topLeftInnerEyeStyle={topLeftInnerEyeStyle}
            setTopLeftInnerEyeStyle={setTopLeftInnerEyeStyle}

            topRightOuterEyeStyle={topRightOuterEyeStyle}
            setTopRightOuterEyeStyle={setTopRightOuterEyeStyle}
            topRightInnerEyeStyle={topRightInnerEyeStyle}
            setTopRightInnerEyeStyle={setTopRightInnerEyeStyle}

            bottomLeftOuterEyeStyle={bottomLeftOuterEyeStyle}
            setBottomLeftOuterEyeStyle={setBottomLeftOuterEyeStyle}
            bottomLeftInnerEyeStyle={bottomLeftInnerEyeStyle}
            setBottomLeftInnerEyeStyle={setBottomLeftInnerEyeStyle}

            outerEyeStyles={outerEyeStyles}
            setOuterEyeStyles={setOuterEyeStyles}

            innerEyeStyles={innerEyeStyles}
            setInnerEyeStyles={setInnerEyeStyles}

            dotStyle={dotStyle}
            setDotStyle={setDotStyle}

            setUsePresetStyle={setUsePresetStyle}
            closePopup={() => setOpenStylesSettings(false)} 
          /> : null}
        </div>


        <div>
          {openFrameSettings ? <PopupFrame  setFillBackground={setFillBackground} tempActiveFrame={tempActiveFrame} setTempActiveFrame={setTempActiveFrame} activeFrame={activeFrame} setActiveFrame={setActiveFrame} closePopup={() => closePopup(setOpenFrameSettings)} /> : null}
        </div>

        <div>
          {openLogoSettings ? <PopupLogo file={file} setFile={setFile} setLogo={setLogo} closePopup={() => setOpenLogoSettings(false)} /> : null}
        </div>
        
        <div id="qrBox" className='box w-full bg-white h-[558px] max-w-[1000px] sm:max-w-[308px] border border-[#D7E0F3] rounded-xl p-6' style={{position:"relative",  zIndex: "9"}}>
          <div className="h-[258px] w-[258px] mx-auto rounded-[15px] overflow-hidden">
            <div className={isImageUpdating ? "animation-loading" : "animation-none"}>
              <div className={`flex justify-center box w-full h-[256px] w-[256px] ${activeFrame || tempActiveFrame ? "p-4": ""} border border-[#D7E0F3] rounded-xl p-0`}
                style={{backgroundColor: activeFrame || tempActiveFrame ? "#FFFFFF": backgroundColor.hex, maxWidth: "308px"}}
              >
                <QRCodeImage data={imageSrc} />
              </div>
            </div>
          </div>

            <div style={{height: "250px", display: "flex", flexFlow: "column"}}>
              <div className='text-left my-2' style={{flex: "1 1 auto"}}>

                <ColorOptions dotColor={dotColor} changeColor={changeColor} setOpenColorsSettings={setOpenColorsSettings} />
                <StyleOptions active={activePresetStyle} styleHandler={changeStyle} setOpenStylesSettings={setOpenStylesSettings} />

                <div className='flex justify-between mb-2'>
                    <button className='button-outline' onClick={() => setOpenFrameSettings(true)} ><img className='image inline mr-2 mb-[2px]' src={frame_logo} alt="frame" />Add frame</button>
                    <button className='button-outline' onClick={() => openPopup(setOpenLogoSettings)}><img className='image inline mr-2 mb-[2px]' src={logo_icon} alt="logo" />Add logo</button>
                </div>

                </div>
              
              <ButtonGroup onClick={handleDownload} selected={downloadImageFileFormat} setSelected={setdownloadImageFileFormat}/>

            </div>

        </div>
      </div>
    </div>
    <CreatedQRs created={created}/>
    </>
  )
}

function App() {
  return (
    <div className="App">

      <div className='bg-bermuda'>
      <div className='max-w-[1164px] w-full flex justify-center mx-auto pt-6 px-4 md:px-0'>
        <img src={logo_qr} className="ml-auto w-[118px]" alt="logo" />
        <div className='ml-auto pt-2 roundex-xl'>
          <a className='text-right text-[18px] mr-4 font-semibold text-blue cursor-pointer underline underline-offset-2 hover:text-sky-700' href='/blog'>Blog</a>
        </div>
      </div>
      </div>

      <BigInput/>

      {/* <div className='font-dmsans flex justify-center bg-bermuda px-6 sm:px-0'>
        <div className='max-w-[1164px] container pb-20 pt-6'>
          <h3 className='text-[25px] text-left font-semibold'>Your QR codes:</h3>

          <div className='flex py-4'>

            <div className='bg-white border border-[#D7E0F3] w-[260px]  max-w-[260px] rounded-xl text-left p-2'>
              <div className='flex truncate'>
                <div className='bg-white border border-[#D7E0F3] rounded-xl p-1'>
                  <img className='min-w-[64px] w-[64px]' src={example_qr} alt="qr" />
                </div>
                <div className='ml-4'>
                  <p className='text-[14px]'>12 seconds ago</p>
                  <p className='text-[16px] font-semibold'>https://google....</p>
                  <p className='font-semibold text-blue cursor-pointer'>more</p>
                </div>
                </div>
            </div>

          </div>

        </div>
      </div> */}

      
      <MainContentSection/>
      <ShareSocial/>
      <FeaturesSection/>
      <FaqSection/>
      <Footer/>

      {/* <a download="qr.svg" href="/api/download_simple" target='_blank'> Download </a>

      <form action="https://light-qrcode.com/api/download_simple" method="get">
        <button className='text-[12px]' type="submit">Download SVG</button>
      </form> */}

    </div>
  );
}

export default App;
