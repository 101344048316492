import '../App.css';
import React from "react";
import { useEffect, useState } from 'react';
import { Button } from './Button'
import { BasePopup } from './BasePopup';
import { ColorPicker } from "react-color-palette";
import Popup from 'reactjs-popup';
import "react-color-palette/css";
import 'reactjs-popup/dist/index.css';


const ColorInput = ({text, open, setOpen, color, setColor, callback}) => {
    const onChangeComplete = (color) => localStorage.setItem("color", color.hex);
    return (
        <div className='mb-4'>
            {text &&
                <label className='text-[16px] font-bold'>{text}</label>
            }
            <div className='flex align-items-center hover:cursor-pointer' onClick={() => setOpen(true)}>
                <div className='inline-block w-[40px] h-[40px] rounded-lg mr-2 border border-[#D7E0F3]' style={{backgroundColor: color.hex}}></div>
                <Popup
                    trigger={<div className="inline"></div>}
                    closeOnDocumentClick={true}
                    open={open}
                    onClose={()=> {setOpen(false)}}
                    position="bottom center"
                    nested
                >
                    <div className=''>
                    <ColorPicker hideInput={["rgb", "hsv"]} hideAlpha={true} color={color} onChange={setColor} onChangeComplete={callback} />
                    </div>
                </Popup>
                <span className='pt-2'>{color.hex}</span>
            </div>
        </div>
    )
}




export const PopupColors = ({ color, setColor, outerEyeColor, setOuterEyeColor, innerEyeColor, setInnerEyeColor, backgroundColor, setBackgroundColor, fillBackground, setFillBackground, setUsePresetColorStyle, closePopup }) => {
    const [open, setOpen] = useState(false)
    const [openOuterEye, setOpenOuterEye] = useState(false)
    const [openInnerEye, setOpenInnerEye] = useState(false)
    const [openBackgroundColor, setOpenBackgroundColor] = useState(false)


    const callback = (color) => {
        setUsePresetColorStyle(false)
    }
    return (
        <BasePopup closePopup={closePopup}>
            <div className='flex flex-col justify-between w-full h-full'>
                    <div>
                        <div className='mb-2'>
                            <span className='text-[18px] font-bold'>Select colors:</span>
                        </div>
                    
                        <ColorInput text={"Dot color:"} open={open} setOpen={setOpen} color={color} setColor={setColor} callback={callback} />
                        <ColorInput text={"Outer Eye color:"} open={openOuterEye} setOpen={setOpenOuterEye} color={outerEyeColor} setColor={setOuterEyeColor} callback={callback} />
                        <ColorInput text={"Inner Eye color:"} open={openInnerEye} setOpen={setOpenInnerEye} color={innerEyeColor} setColor={setInnerEyeColor} callback={callback} />


                        <label className='text-[16px] font-bold'>Background:</label>
                        {fillBackground &&
                            <ColorInput open={openBackgroundColor} setOpen={setOpenBackgroundColor} color={backgroundColor} setColor={setBackgroundColor} callback={callback} />
                        }
                        <div className='flex align-center mt-2 mb-2'>
                            <input
                                className="text-violet-500 mr-2 border-gray-300 peer rounded-lg w-6 h-6"
                                id="background"
                                type="checkbox"
                                checked={fillBackground}
                                onChange={() => setFillBackground(!fillBackground)}
                                value="background"
                            />
                            <label
                                className="cursor-pointer"
                                htmlFor="background"
                            >
                                <p className="inline">Fill background</p>
                            </label>
                        </div>



                    </div>
                    
                    <div className='flex pb-4'>
                        <Button className="button-alert mx-2" onClick={()=>{closePopup()}} text="Close"/>
                        <Button className="button mx-2" onClick={()=>{closePopup()}} text="Apply"/>
                    </div>
                </div>
        </BasePopup>
    );
};