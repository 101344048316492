import logo_qr from '../logo-lightqr.svg';

export function Footer() {
    return (
        <div className='font-dmsans flex justify-center bg-bermuda px-6 sm:px-0'>
            <div className='max-w-[1164px] container pb-20 pt-6'>
            <hr className='mb-8' />
            
            <img src={logo_qr} className="w-[118px] mx-auto md:mx-0" alt="logo" />
            <p className='text-[14px] mb-2'>Light QR code generator © 2024</p>
            <p className='text-[12px] text-slate-400'>“QR Code” is a registered trademark of DENSO WAVE INCORPORATED</p>
            
            </div>
        </div>
    )
}
