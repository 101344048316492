import '../App.css';
import React from "react";
import { useEffect, useState } from 'react';
// import UploadAndDisplayImage from './ImageUploader'
import { Button } from './Button'
import frame from '../static/frames/scan_me_green.svg'
import frame_2 from '../static/frames/orange_right.svg'
import frame_3 from '../static/frames/green_side.svg'
import frame_4 from '../static/frames/blue_bottom.svg'
import frame_5 from '../static/frames/red_outline.svg'
import frame_6 from '../static/frames/blue_top.svg'
import { BasePopup } from './BasePopup';


function FrameOption({frame, tempActiveFrame, activeFrame, currentFrame, setActiveFrame}) {
    const active = (activeFrame === currentFrame || tempActiveFrame === currentFrame)
    return(
        <>
            <div
                value={currentFrame}
                className='flex align-center justify-center m-3 image-select hover:cursor-pointer'
                onClick={(e) => setActiveFrame(e.currentTarget.getAttribute("value"))}
                style={active ? {"outline":"2px solid #2366E8"} : {"outline":"2px solid #a6b1c8"}}>

                <img className='image inline m-auto max-h-full p-2' src={frame} alt="frame" />
            </div>
        </>
    )
}

export const PopupFrame = ({ setFillBackground, closePopup, tempActiveFrame, setTempActiveFrame, activeFrame, setActiveFrame }) => {

    const applyFrame = () =>{
        if (tempActiveFrame) {
            setActiveFrame(tempActiveFrame);
            setTempActiveFrame(undefined);
        }
        closePopup();
        // setFillBackground(false);
    }
    const closePopupAndResetFrame = () => {
        if (activeFrame){
            setActiveFrame(undefined)
        }
        closePopup();
    }
    return (
        <BasePopup closePopup={closePopup}>
            <div className='flex flex-col justify-between w-full h-full'>
                    <div>
                        <div>
                            <span className='text-[16px] font-bold'>Select a frame:</span>
                        </div>
                        <div className='flex flex-wrap max-h-[700px]' style={{ background: "#F9FBFF", overflowY:"auto" }}>
                            <FrameOption key={"one"} frame={frame} currentFrame={"frame_scan_me_green"} tempActiveFrame={tempActiveFrame} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/>
                            <FrameOption key={"two"} frame={frame_2} currentFrame={"frame_orange_right"} tempActiveFrame={tempActiveFrame} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/>
                            <FrameOption key={"three"} frame={frame_3} currentFrame={"frame_green_side"} tempActiveFrame={tempActiveFrame} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/>
                            <FrameOption key={"four"} frame={frame_4} currentFrame={"frame_blue_bottom"} tempActiveFrame={tempActiveFrame} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/>
                            <FrameOption key={"six"} frame={frame_6} currentFrame={"frame_blue_top"} tempActiveFrame={tempActiveFrame} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/>
                            <FrameOption key={"five"} frame={frame_5} currentFrame={"frame_red_outline"} tempActiveFrame={tempActiveFrame} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/>

                            {/* <FrameOption key={"3"} frame={frame}  currentFrame={"3"} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/>
                            <FrameOption key={"4"} frame={frame} currentFrame={"4"} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/>
                            <FrameOption key={"5"} frame={frame} currentFrame={"5"} activeFrame={activeFrame} setActiveFrame={setTempActiveFrame}/> */}
                        </div>
                    </div>
                    <div className='flex pb-4'>
                        <Button className="button-alert mx-2" onClick={() => {closePopupAndResetFrame()}} text={activeFrame ? "Remove": "Cancel"}/>
                        <Button className="button mx-2" onClick={() => {applyFrame()}} text="Apply"/>
                    </div>
                </div>
        </BasePopup>
    );
};