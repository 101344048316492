import React, { useState, useRef } from "react";

import { Button } from './Button'

// Define a functional component named UploadAndDisplayImage
const ImageUpload = ({file, setFile, handleFileChange}) => {
  // Define a state variable to store the selected image
  const hiddenFileInput = useRef(null); // ADDED

  const handleClick = event => {
    hiddenFileInput.current.click();    // ADDED
  };

  // Return the JSX for rendering
  return (
    <div>
      {/* Header */}
      <h3 className="mb-2">Select an image</h3>

      {/* Conditionally render the selected image if it exists */}
      {file && (
        <div className="mx-auto">
          {/* Display the selected image */}
          <div className="max-w-[100px] mx-auto">
            <img
              alt="not found"
              width={"250px"}
              src={URL.createObjectURL(file)}
            />
          </div>
          <div className="w-100 mb-4">
            <Button className="button-outline block mt-4 mx-auto" onClick={() => setFile(null)}  text="Remove"/>
          </div>
        </div>
      )}

      {!file && (
        <>
        <h3 className="mb-2">Supported format: SVG</h3>
        <Button className="button" onClick={handleClick}  text="Upload"/>
        </>
      )}

      <input
        type="file"
        name="myImage"
        accept=".svg"
        ref={hiddenFileInput}
        style={{display:'none'}}

        // Event handler to capture file selection and update the state
        onChange={handleFileChange}
      />
    </div>
  );
};

// Export the UploadAndDisplayImage component as default
export default ImageUpload;