import React, { useState } from 'react';


const faqData = [
    {
        question: "Is the QR code generator really free?",
        answer: "Yes, our QR code generator is completely free to use. There are no hidden costs or subscription fees, allowing you to create unlimited QR codes without spending a dime."
    },
    {
        question: "Do I need to sign up to use the QR code generator?",
        answer: "No sign-up is required. You can start creating QR codes immediately without the need for any registration or account creation."
    },
    {
        question: "Can I customize the design and color of my QR code?",
        answer: "Yes, you can fully customize the design and color of your QR code. Choose from various color options and design styles to match your branding or personal preference."
    },
    {
        question: "How do I add my logo to a QR code?",
        answer: "You can easily brand your QR code by uploading your logo in SVG format. This helps make your QR code stand out and aligns it with your brand identity."
    },
    {
        question: "Are there pre-made frames available for the QR codes?",
        answer: "Yes, we offer a variety of pre-made frames that you can use to enhance the appearance of your QR code. These frames include text and design elements like 'Scan Me' to encourage engagement."
    },
    {
        question: "What file formats can I download my QR code in?",
        answer: "You can download your QR code in multiple formats including SVG, PNG, and JPEG. This allows you to use your QR code in various contexts, from digital media to print."
    },
    {
        question: "Is there a limit to how many QR codes I can generate?",
        answer: "No, there is no limit. You can generate as many QR codes as you need, all for free."
    },
    {
        question: "Can I track the usage of my QR codes?",
        answer: "While our QR code generator focuses on ease of use and customization, it does not include tracking features. However, you can integrate third-party tracking services with your QR code."
    },
    {
        question: "What types of content can I link to with my QR code?",
        answer: "You can link to a variety of content types, including URLs, contact information, Wi-Fi passwords, text, emails, and more, making it versatile for all your needs."
    },
    {
        question: "Can I create a QR code for my business card?",
        answer: "Yes, you can create a QR code that links to your digital business card or vCard, allowing others to easily save your contact details directly to their phone."
    },
    {
        question: "Is there an expiration date for the QR codes generated?",
        answer: "No, the QR codes generated with our tool do not expire. They remain active and usable for as long as you need them."
    },
    {
        question: "Can I create QR codes in bulk?",
        answer: "Currently, our service is designed for generating individual QR codes, but you can create as many as you need manually without any restrictions."
    },
    {
        question: "How can I make sure my QR code is scannable?",
        answer: "To ensure your QR code is scannable, use high contrast between the QR code and background colors, avoid too much clutter around the code, and test it with multiple devices before finalizing."
    },
    {
        question: "What are the best practices for placing QR codes on marketing materials?",
        answer: "When placing QR codes on marketing materials, ensure they are easily visible, provide clear instructions such as 'Scan Me,' and use a URL shortener if needed to create a cleaner design."
    },
    {
        question: "How do I customize the frame for my QR code?",
        answer: "To customize the frame of your QR code, select from our set of pre-designed frames and adjust the color and text as needed. This feature helps you create a more engaging QR code that aligns with your brand or campaign."
    }
];

export function FaqSection() {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className="font-dmsans py-10 bg-gray-50 sm:py-16 lg:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="mb-16">
                    <h6 className="text-lg text-indigo-600 font-medium text-center mb-2">
                        FAQs
                    </h6>
                    <h2
                        className="text-4xl text-center font-bold text-gray-900 leading-[3.25rem]"
                    >
                        Frequently asked questions
                    </h2>
                </div>


                <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                    {faqData.map((item, index) => (
                        <div key={index} className={`faq-item transition-all duration-200 bg-white border border-gray-200 rounded-xl ${openIndex === index ? 'shadow-lg' : ''} cursor-pointer hover:bg-gray-50`} >
                            <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6"  onClick={() => toggleFAQ(index)}>
                                <span className="flex text-lg font-semibold text-black">{item.question}</span>
                                <svg className={`w-6 h-6 text-gray-400 transition-transform duration-200 ${openIndex === index ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>
                            <div
                                className={`px-4 sm:px-6 transition-max-height duration-300 ease-in-out overflow-hidden ${openIndex === index ? 'max-h-40 py-5 sm:pb-6' : 'max-h-0'
                                    }`}
                            >
                                <p className="text-gray-600">{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <p className="text-center text-gray-600 textbase mt-9">Didn’t find the answer you are looking for? <a href="#" title="" className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline">Contact our support</a></p>
            </div>
        </section>
    );
};

export function FaqSection2() {
    return (
        <>
            <div className='font-dmsans flex justify-center bg-bermuda px-6 sm:px-0'>
                <div className='max-w-[1164px] container pb-20 pt-6 md:px-4'>
                    <section className="py-10">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="mb-16">
                                <h6 className="text-lg text-indigo-600 font-medium text-center mb-2">
                                    FAQs
                                </h6>
                                <h2
                                    className="text-4xl text-center font-bold text-gray-900 leading-[3.25rem]"
                                >
                                    Frequently asked questions
                                </h2>
                            </div>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Is the QR code generator really free?</summary>
                                <div class="px-4 mb-8">
                                    <p>Yes, our QR code generator is completely free to use. There are no hidden costs or subscription fees, allowing you to create unlimited QR codes without spending a dime.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Do I need to sign up to use the QR code generator?</summary>
                                <div class="px-4 mb-8">
                                    <p>No sign-up is required. You can start creating QR codes immediately without the need for any registration or account creation.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Can I customize the design and color of my QR code?</summary>
                                <div class="px-4 mb-8">
                                    <p>Yes, you can fully customize the design and color of your QR code. Choose from various color options and design styles to match your branding or personal preference.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">How do I add my logo to a QR code?</summary>
                                <div class="px-4 mb-8">
                                    <p>You can easily brand your QR code by uploading your logo in SVG format. This helps make your QR code stand out and aligns it with your brand identity.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Are there pre-made frames available for the QR codes?</summary>
                                <div class="px-4 mb-8">
                                    <p>Yes, we offer a variety of pre-made frames that you can use to enhance the appearance of your QR code. These frames include text and design elements like "Scan Me" to encourage engagement.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">What file formats can I download my QR code in?</summary>
                                <div class="px-4 mb-8">
                                    <p>You can download your QR code in multiple formats including SVG, PNG, and JPEG. This allows you to use your QR code in various contexts, from digital media to print.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Is there a limit to how many QR codes I can generate?</summary>
                                <div class="px-4 mb-8">
                                    <p>No, there is no limit. You can generate as many QR codes as you need, all for free.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Can I track the usage of my QR codes?</summary>
                                <div class="px-4 mb-8">
                                    <p>While our QR code generator focuses on ease of use and customization, it does not include tracking features. However, you can integrate third-party tracking services with your QR code.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">What types of content can I link to with my QR code?</summary>
                                <div class="px-4 mb-8">
                                    <p>You can link to a variety of content types, including URLs, contact information, Wi-Fi passwords, text, emails, and more, making it versatile for all your needs.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Can I create a QR code for my business card?</summary>
                                <div class="px-4 mb-8">
                                    <p>Yes, you can create a QR code that links to your digital business card or vCard, allowing others to easily save your contact details directly to their phone.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Is there an expiration date for the QR codes generated?</summary>
                                <div class="px-4 mb-8">
                                    <p>No, the QR codes generated with our tool do not expire. They remain active and usable for as long as you need them.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">Can I create QR codes in bulk?</summary>
                                <div class="px-4 mb-8">
                                    <p>Currently, our service is designed for generating individual QR codes, but you can create as many as you need manually without any restrictions.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 text-[20px] outline-none cursor-pointer focus:underline">How can I make sure my QR code is scannable?</summary>
                                <div class="px-4 mb-8">
                                    <p>To ensure your QR code is scannable, use high contrast between the QR code and background colors, avoid too much clutter around the code, and test it with multiple devices before finalizing.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">What are the best practices for placing QR codes on marketing materials?</summary>
                                <div class="px-4 mb-8">
                                    <p>When placing QR codes on marketing materials, ensure they are easily visible, provide clear instructions such as “Scan Me,” and use a URL shortener if needed to create a cleaner design.</p>
                                </div>
                            </details>

                            <details>
                                <summary class="py-2 mb-4 text-[20px] outline-none cursor-pointer focus:underline">How do I customize the frame for my QR code?</summary>
                                <div class="px-4 mb-8">
                                    <p>To customize the frame of your QR code, select from our set of pre-designed frames and adjust the color and text as needed. This feature helps you create a more engaging QR code that aligns with your brand or campaign.</p>
                                </div>
                            </details>

                        </div>
                    </section>
                </div>
            </div>

        </>
    )
}
