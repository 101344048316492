import './ButtonGroup.css';
import React from 'react';
import DownloadIcon from '../../static/download.svg'

export function ButtonGroup({onClick, selected, setSelected, className, text}){
    return (
      <>
      <div class="button-group-container flex w-100 text-lg mb-4" role="group">
        <button onClick={onClick} class="button-group flex-1 rounded-l-lg px-4 py-2 mx-0 outline-none focus:shadow-outline">
          <img className='inline pb-1 mr-2' src={DownloadIcon} alt='Download generated qr code'/>
          Download
        </button>

        <select 
          value={selected}
          onChange={e => setSelected(e.target.value)}
          className='button-select rounded-r-lg pl-3 pr-6 py-2 mx-0 outline-none focus:shadow-outline'>
          <option value="svg">SVG</option>
          <option value="png">PNG</option>
          <option value="jpeg">JPEG</option>
          {/*<option value="webp">WebP</option>
          <option value="pdf">PDF</option> */}
        </select>
      </div>
      </>
    )
  }